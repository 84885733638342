.box-layout {
  @extend .flex-column-container;
  align-items: center;
  // background: url('/images/bg.jpg');
  // background-size: cover;
  // background: rgb(255, 106, 180);
  // background: linear-gradient(135deg, rgba(255, 106, 180, 1) 44%, rgba(255, 177, 192, 1) 74%);
  background-color: snow;
  display: flex;
  height: 50vh;
  justify-content: center;
  // width: 100vw;
}

.box-layout__box {
  background: fade-out(white, .15);
  border-radius: 3px;
  padding: $l-size $m-size;
  text-align: center;
  width: 25rem;
}

.box-layout__title {
  margin: 0 0 $m-size 0;
  line-height: 1;
}
