.sidebar-container {
  @extend .flex-column-container;
  @extend .idolsekai-shadow-soft;

  order: 1;
  // border-style: solid;
  // border-width: 2px;
  // border-color: rgba(148, 148, 148, 0.4);

  height: 100%;

  background-color: white;
  margin-top: 40%;
  margin-right: 4%;
  margin-bottom: 70%;
  justify-content: space-between;
}

.sidebar-new-row-container {
  @extend .flex-row-container;

  margin-top: 5%;
}

.sidebar-new-row-icon {
  order: 0;
  margin-left: 5%;
}

.sidebar-new-row-label {
  order: 1;
  align-self: center;
  margin-left: 5%;
  color: grey;
}

.sidebar-rows-container {
  @extend .flex-column-container;

  margin-top: 10%;
  overflow-y: auto;
  text-align: center;
}

.sidebar-ranker-button {
  font-family: 'Lato', sans-serif;
}
.sidebar-ranker-button:hover {
  background-color: #4882ff;
  color: white;
}

.sidebar-ranker-button-enabled {
  background-color: #4882ff;
}

.sidebar-ranker-button-disabled {
  background-color: white;
  color: grey;
}
