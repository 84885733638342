.ranker-container {
  @extend .flex-column-container;

  order: 0;
  // width: 60%;
  height: 100%;
}

.ranker-header {
  order: 0;
  height: 6%;
}

.ranker-header-name {
  @extend .flex-row-container;

  justify-content: center;
  width: 100%;
}

.ranker-header-name-label {
  @extend .flex-column-container;
  @extend .idolsekai-shadow-soft;

  justify-content: center;

  background-color: white;
  color: grey;

  min-width: 20%;
  text-align: center;
}

.ranker-header-name-buttons {
  display: none;
}

.ranker-header-name:hover .ranker-header-name-buttons {
  display: flex;
  // margin-left: 2%;
  background-color: white;
}

.ranker-rows-container {
  @extend .flex-column-container;

  order: 1;
  // max-height: 80%;
  height: 80%;
  overflow-y: auto;
}

.ranker-row {
  overflow-x: auto;
}

.ranker-row-container {
  @extend .flex-row-container;
}

.ranker-row-item-container {
  @extend .flex-row-container;

  order: 0;
  // height: 200px;
  width: 90%;
  overflow-x: auto;
  justify-content: center;
}

.ranker-row-right-side-buttons-container {
  @extend .flex-column-container;

  order: 1;
}

.ranker-row-right-side-button-plus {
  order: 0;
  text-align: center;
}

.ranker-row-right-side-label {
  order: 1;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ranker-row-right-side-button-minus {
  order: 2;
  text-align: center;
}

.ranker-row-remove-row-button {
  order: 2;
  // display: none;
  opacity: 0;
}

.row-header:hover .ranker-row-remove-row-button {
  order: 2;
  opacity: 1;
}

.ranker-footer-container {
  @extend .flex-column-container;

  order: 2;
  justify-content: center;
  bottom: 1%;
  position: sticky;
  // background-color: red;
}

.ranker-footer-add-row {
  order: 0;
  margin-top: 0.5%;
  margin-bottom: 1%;
  text-align: center;
}

.ranker-footer-options-container {
  @extend .flex-row-container;

  justify-content: center;
  opacity: 0.7;
}

.ranker-footer-options-container:hover {
  opacity: 1;
}
