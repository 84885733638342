.idolsekai-header {
  // background: #ef91b1;
  background: white;
  height: 9vh;
  position: sticky;
  top: 0;
}

.header__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $s-size 0;
}

.header__title {
  color: white;
  text-decoration: none;
  h1 {
    margin: 0;
  }
}
