.button {
  background: $blue;
  border: none;
  color: white;
  display: inline-block;
  font-size: $font-size-large;
  font-weight: 300;
  line-height: 1;
  padding: 0.4rem;
  text-decoration: none;
}

.button--link {
  background: none;
  font-family: 'Lato', sans-serif;
}

.button--link:hover {
  opacity: 0.4;
}

.button--secondary {
  background: #888;
}
