* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  color: $dark-grey;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: $m-size;
  line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.idolsekai-background {
  background: rgb(255, 106, 181);
  background: linear-gradient(135deg, rgba(255, 106, 180, 1) 44%, rgba(255, 177, 192, 1) 74%);
}

.idolsekai-single-color {
  background: rgba(255, 177, 192, 1);
}

.idolsekai-dark-blue {
  background-color: #4882ff;
}

.idolsekai-default-font {
  font-family: 'Lato', sans-serif;
}

.isk-medium-font {
  font-size: $font-idol-avatar-groups-jpop;
}

.isk-small-font {
  font-size: $font-idol-card-info;
}

.isk-xsmall-font {
  font-size: $isk-font-size-xsmall
}

.idolsekai-shadow-soft {
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

.idolsekai-ranker-logo {
  @extend .flex-row-container;
  background-color: #ffffff;
  justify-content: center;
  margin-bottom: 1%;
}

// adblock blocks some icons with social media names
.sidebar-new-row-icon i,
.ranker-footer-add-row i {
  color: white;
}

.idolsekai-disabled-opacity {
  opacity: 0.4;
}

.idolsekai-akb-font {
  font-family: "akbfont";
}

@font-face {
  font-family: "akbfont";
  src: local("akbfont"), url("/assets//fonts/akbfont.ttf") format("woff");
}
