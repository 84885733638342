.row-header {
  @extend .flex-row-container;

  padding: 8px 8px 0 8px;
}

.row-header-name {
  @extend .flex-row-container;

  justify-content: center;
  width: 100%;
}

.row-header-name-label {
  @extend .flex-column-container;

  justify-content: center;

  background-color: white;
  color: grey;

  min-width: 20%;
  margin-left: 3%;
  text-align: center;
  padding-bottom: 1px;
}

.row-header-name-buttons {
  display: none;
}

.row-header:hover .row-header-name-buttons {
  display: flex;
  background-color: white;
}
