// Colors
$dark-grey: #333;
$grey: #666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;
// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.3rem;
$font-size-xsmall: 1.2rem;
$isk-font-size-xsmall: 0.7vw;
// Extra font
$font-idol-avatar-name: 0.9vw;
$font-idol-avatar-groups-jpop: 1.1vw;
$font-idol-avatar-groups-kpop: 0.9vw;
$font-idol-card-info: 0.8vw;





// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
