.loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.loading-small {
  height: 3rem;
  width: 3rem;
}

.loading-medium {
  height: 6rem;
  width: 6rem;
}

.loading-large {
  height: 8rem;
  width: 8rem;
}

.loader__image {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.loading-ranker-container {
  display: flex;
  margin-top: 30%;
  align-self: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
