.idol-list-container {
  @extend .flex-column-container;
  @extend .sticky-default-height;
  // width: 100%;
}

.idol-list-input {
  order: 0;
}

.idol-list {
  order: 1;
  overflow-y: scroll;
  height: 95.8%;
  // margin-bottom: 5px;
}

.idol-list-load-more {
  order: 2;
  display: flex;
  justify-content: center;
  background-color: #55acee;
}

.idol-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 1px solid #eae7e7;
  height: 23vh;
  background-color: white;
}

.idol-list-item-avatar-container {
  order: 0;
  display: flex;
  flex-direction: column;
  // width: 35%;
  // height: 18vh;
  justify-content: center;
}

.idol-list-item-avatar-image-container {
  order: 0;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  // height: 11vh;
  .ui.tiny.image {
    max-height: 14vh;
  }
}

.idol-list-item-avatar-image {
  order: 1;
  text-align: center;
  align-self: center;
  // background-image: url('https://verilymag.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_faces:center%2Cq_auto:good%2Cw_768/MTU5OTA3NzU3MDUzNDUzODU2/15816785742_0f270025a5_o.jpg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.idol-list-item-avatar-image-name-kanji {
  order: 0;
  text-align: center;
  width: 35px;
  word-break: break-word;
  line-height: 120%;
  font-size: $font-size-small;
  color: white;
  font-weight: 800;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.styled-name-label {
  height: 14vh;
  justify-content: space-evenly;
  width: 35px;
}

.styled-name-label-character {
  align-self: center;
  color: white;
  font-weight: 800;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  writing-mode: vertical-rl;
}

.idol-list-item-avatar-name {
  order: 1;
  text-align: center;
  font-size: $font-idol-avatar-name;
  // margin-bottom: 8px;
}

.group-label-default-font-size {
  font-size: $font-idol-avatar-groups-kpop;
}

.idol-list-item-avatar-groups-jpop {
  @extend .flex-row-container;

  justify-content: space-evenly;
  margin-left: 22%;
  font-size: $font-idol-avatar-groups-jpop;
}

.idol-list-item-avatar-groups-kpop {
  @extend .flex-row-container;

  justify-content: space-evenly;
  margin-left: 22%;
  // max-height: 30px;
  font-size: $font-idol-avatar-groups-kpop;
}

.idol-list-item-avatar-details-container {
  @extend .flex-column-container;

  order: 2;
  text-align: center;
  // font-style: italic;
  // font-size: 0.8rem;
  justify-content: space-evenly;
  // flex-basis: 15%;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  width: 40%;
  overflow-y: auto;
}

.idol-list-item-avatar-details-age {
  order: 0;
  text-align: center;
  font-size: $font-idol-card-info;
}

.idol-list-item-avatar-details-location {
  order: 1;
  text-align: center;
  font-size: $font-idol-card-info;
}

.idol-list-item-avatar-details-status {
  order: 1;
  text-align: center;
  font-size: $font-idol-card-info;
}

.idol-list-item-avatar-details-wiki {
  order: 1;
  text-align: center;
  font-size: $font-idol-card-info;
}

.idol-list-item-info-container {
  @extend .flex-row-container;

  order: 2;
  // justify-content: center;
  width: 65%;
  overflow-x: auto;
}

.idol-list-item-group-container {
  @extend .flex-row-container;

  order: 2;
  align-items: center;
  // margin-bottom: 5px;
  width: 60%;
  max-width: 60%;
  overflow-x: auto;
  margin-left: 2%;
}

.idol-list-item-group-jpop {
  @extend .flex-column-container;

  order: 0;
  flex-shrink: 0;
  flex-grow: 0.3;
  // width: 80px;
  flex-basis: 80px;
  margin-right: 10%;
  // margin-top: 5%;
}

.idol-list-item-group-kpop {
  @extend .flex-column-container;

  order: 0;
  flex-shrink: 0;
  flex-grow: 0.3;
  // width: 80px;
  flex-basis: 80px;
  margin-right: 10%;
  // margin-top: 5%;
}

.idol-list-item-group-logo {
  order: 0;
  //height: 50px;
}
.idol-list-item-group-team {
  text-align: center;
  //height: 30px;
  // margin-top: 1px;

  // border-radius: 25px;
}

.idol-list-item-extra-info-container {
  order: 3;
  display: flex;
  flex-direction: row;
}

.idol-list-item-extra-info-status {
  order: 0;
}

.idol-list-item-extra-info-rank {
  order: 1;
}

.idol-list-item-extra-info-wiki {
  order: 2;
  margin-left: 5px;
}

.idol-list-item-heart-container {
  order: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15%;
}

.idol-list-item-heart {
  order: 0;
}
