.sticky-idol-list-container {
  @extend .flex-column-container;
  @extend .sticky-default-height;

  position: sticky;
  top: 9vh;
  flex-basis: 25%;
  max-width: 25%;
  background-color: aliceblue;
}

.sticky-ranker-container {
  @extend .flex-column-container;

  height: 100%;
  flex-basis: 65%;
  max-width: 65%;

  overflow: visible;
  // Firefox is not assuming the flex basis value, so we need width
  // width: 65%;
  // width: 65vw;
}

.sticky-sidebar-container {
  @extend .flex-column-container;
  @extend .sticky-default-height;

  position: sticky;
  top: 9vh;
  flex-basis: 10%;
  max-width: 10%;
  // width: 20vw

}

.sticky-default-height {
  height: 91vh;
}
