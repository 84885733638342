.credits-container {
  @extend .flex-column-container;

  text-align: center;
  flex-basis: 50%;
  border-style: solid;
  border: 2px 0 2px 0;
  border-color: #0000000f;
  justify-content: space-evenly;
}
